import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';

interface Relay {
  name: string;
  "background-color": string;
}

@Component({
  selector: 'lib-status-lights-controller',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatCardModule,
  ],
  templateUrl: './status-lights-controller.component.html',
  styleUrl: './status-lights-controller.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusLightsControllerComponent {
  private http = inject(HttpClient);
  public relayButtons: Relay[] = [
    {name: 'white', 'background-color': 'white'},
    {name: 'blue', 'background-color': 'blue'},
    {name: 'red', 'background-color': 'red'},
    {name: 'amber', 'background-color': '#FFBF00'},
    {name: 'green', 'background-color': 'green'},
    {name: 'buzzer', 'background-color': 'grey'},
    // {name: 'relay7', 'background-color': 'black'},
    // {name: 'relay8', 'background-color': 'black'},
  ];

  public handleRelayClick(change: MatButtonToggleChange): void {
    const [relay, status] = [
      change.source.buttonToggleGroup.name,
      change.source.value,
    ]

    this.http.post('/waveshare', `{${relay}:${status}}`)
      .subscribe((response) => console.log('This is always an error', response));
  }
}
