import { Task } from './task';
import { BaseData } from './_global';
import { DRIVE_FOLDER_URL } from '@dis/gapi';
import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from '@angular/fire/firestore';
import { JobBilling } from './job-billing';
import { JobDates } from './dates';
import { ShipmentAddresses } from './addresses';

export interface Job extends BaseData {
  jobNumber: string;
  projectNumber: string;
  description: string;
  holeSection: string;
  status: string;
  shippingContainer: string;
  dates: JobDates;

  notes: string;
  folderId: string;
  jobId: string;
  tasks: Task[];
  list: string;
  customer: string;
  well: string;
  rig: string;
  location: string;
  network: string;
  ocsgLease: string;

  notesArray: any[];

  billing: JobBilling;
  addresses: ShipmentAddresses;

  metadata: JobMetadata;
}

export class Job extends BaseData {
  constructor(data: Partial<Job>) {
    super(data);
    this.uid = data.jobNumber || null;
    this.jobNumber = data.jobNumber || null;
    this.folderId = data.folderId || data.jobFolderId || null;
    this.description = data.description || null;
    this.holeSection = data.holeSection || null;
    this.projectNumber = data.projectNumber || null;
    this.customer = data.customer || this.projectNumber?.split('-')[0].toLowerCase().trim() || null;
    this.network = data.network || null;
    this.ocsgLease = data.ocsgLease || null;
    this.location = data.location || null;
    this.well = data.well || null;
    this.rig = data.rig || null;
    this.status = data.status || null;
    this.tasks = data.tasks || [];
    this.list = data.list || null;
    this.shippingContainer = data.shippingContainer || null;
    this.dates = data.dates || null;
    this.notes = data.notes || null;
    this.notesArray = this.getNotesArray(this.notes);
    this.billing = data.billing || null;
    this.addresses = data.addresses || null;
    this.metadata = data.metadata || null;

    this.fromSpreadsheet(data);
  }

  public get title() {
    return this.jobNumber;
  }

  public get customerIconUrl() {
    // return `cdn/images/customers/${this.customer}.png`;
    return `assets/images/customers/${this.customer}/icon.png`
  }

  public get customerLogoUrl() {
    return `assets/images/customers/${this.customer}/logo.png`
  }

  /**
   * @deprecated
   */
  public get jobFolderId() {
    return this.folderId;
  }

  public get folderUrl() {
    return `${DRIVE_FOLDER_URL}${this.folderId}`;
  }

  public get splitProjectNumber() {
    return this.projectNumber?.split('-').slice(1).join('-');
  }

  private fromSpreadsheet(job: Partial<Job>) {
    // // @ts-expect-error
    // this.shipments = JSON.parse(job.shipments);

    if (typeof job.billing === 'string') {
      this.billing = JSON.parse(job.billing);
    }

    if (typeof job.dates === 'string') {
      this.dates = JSON.parse(job.dates);
    }

    if (typeof job.addresses === 'string') {
      this.addresses = JSON.parse(job.addresses);
    }

    if (typeof job.metadata === 'string') {
      this.metadata = JSON.parse(job.metadata);
    }

  }

  static toSpreadsheet(job: Job) {
    //@ts-expect-error
    job.addresses = JSON.stringify(job.addresses);
    //@ts-expect-error
    job.dates = JSON.stringify(job.dates);
    //@ts-expect-error
    job.billing = JSON.stringify(job.billing);
    //@ts-expect-error
    job.metadata = JSON.stringify(job.metadata);
    // console.log(job);
    return job;
  }

  private getNotesArray(noteString: string) {
    if (noteString) {
      const noteArray = noteString.split('_');
      return noteArray.reverse();
    } else {
      const noteArray = [];
      return noteArray;
    }
  }
}

export const jobConverter = {
  toFirestore(job: Job): DocumentData {
    return { ...job };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Job {
    const data = snapshot.data(options);
    return new Job(data);
  }
};

export interface JobMetadata {
  archiveBool: boolean;
  callOutBool: boolean;
  purchaseBool: boolean;
  rentalBool: boolean;
  stagedBool: boolean;
  tempJobBool: boolean;
  testJobBool: boolean;
  toolTypeRequired: string[];
  tpiBool: boolean;
}

