import { Component, ChangeDetectionStrategy, input, inject } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { GoogleDriveApiService, FileInfo } from '@dis/gapi';

@Component({
  selector: 'shared-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css'],
  standalone: true,
  imports: [
    MatListModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListComponent {
  documents = input.required<FileInfo[]>();
  driveApi = inject(GoogleDriveApiService);

  async handleDocumentDelete(fileId: string, index: number) {
    await this.driveApi.delete(fileId);
    this.documents().splice(index, 1);
  }

}
