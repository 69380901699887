export interface JobDates {
    // Container Dates
    containerDatesOut: Date[];
    containerDatesIn: Date[];
    // Dates for tool leaving/returning from DIS Shop
    datesOut: Date[];
    datesIn: Date[];
    // Rental Dates
    rentalStartDates: Date[];
    rentalEndDates: Date[];
    // Other dates
    spudDate: Date;
    estimatedShipDate: Date;
    returningDate: Date;
    currentDateOut: Date;
    currentRentalStartDate: Date;
    currentRentalEndDate: Date;
    jobStartDate: Date;
    jobEndDate: Date;
    shopCompleteByDate: Date;
}

export class JobDates {
    constructor(data: Partial<JobDates> = {},) {
        // Date Array Fields
        this.containerDatesOut = data.containerDatesOut ? data.containerDatesOut.map(date => new Date(date)) : null;
        this.containerDatesIn = data.containerDatesIn ? data.containerDatesIn.map(date => new Date(date)) : null;
        this.datesOut = data.datesOut ? data.datesOut.map(date => new Date(date)) : null;
        this.datesIn = data.datesIn ? data.datesIn.map(date => new Date(date)) : null;
        this.rentalStartDates = data.rentalStartDates ? data.rentalStartDates.map(date => new Date(date)) : null;
        this.rentalEndDates = data.rentalEndDates ? data.rentalEndDates.map(date => new Date(date)) : null;
        // Date Fields
        this.spudDate = data.spudDate ? new Date(data.spudDate) : null;
        this.estimatedShipDate = data.estimatedShipDate ? new Date(data.estimatedShipDate) : null;
        this.returningDate = data.returningDate ? new Date(data.returningDate) : null;
        this.currentDateOut = data.currentDateOut ? new Date(data.currentDateOut) : null;
        this.currentRentalStartDate = data.currentRentalStartDate ? new Date(data.currentRentalStartDate) : null;
        this.currentRentalEndDate = data.currentRentalEndDate ? new Date(data.currentRentalEndDate) : null;
        this.jobStartDate = data.jobStartDate ? new Date(data.jobStartDate) : null;
        this.jobEndDate = data.jobEndDate ? new Date(data.jobEndDate) : null;
        this.shopCompleteByDate = data.shopCompleteByDate ? new Date(data.shopCompleteByDate) : null;
    }
}

