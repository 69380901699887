@for(relay of relayButtons; track relay) {
<mat-card [ngStyle]="{'background-color': relay['background-color']}">
  <mat-card-header>
    <mat-card-title>
      <h4>{{ relay.name | titlecase }}</h4>
    </mat-card-title>
  </mat-card-header>
  <mat-card-actions>
    <mat-button-toggle-group (change)="handleRelayClick($event)" [name]="relay.name" >
      <mat-button-toggle value="on">On</mat-button-toggle>
      <mat-button-toggle value="off">Off</mat-button-toggle>
    </mat-button-toggle-group>
  </mat-card-actions>
</mat-card>
}
