import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
  pure: false,
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const now = new Date();
      const dateValue = new Date(value);
      const seconds = Math.floor((+now - +dateValue) / 1000);
      const isFuture = seconds < 0;
      const absSeconds = Math.abs(seconds);
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(absSeconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + (isFuture ? ' away' : ' ago'); // singular
          } else {
            return counter + ' ' + i + 's' + (isFuture ? ' away' : ' ago'); // plural
          }
        }
      }
    }
    return value;
  }
}
