import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AclType, AuthService } from '@dis/auth';

@Directive({
  selector: '[hasRole]',
  standalone: true,
})
export class HasRoleDirective {

  role: AclType;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set hasRole(val: AclType) {
    this.role = val;
    this.updateView();
  }

  /**
   * Update view to keep or delete current element.
   */
  private updateView() {
    if (this.authService[this.role]()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
